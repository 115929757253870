export const theme2022 = {
  colors: {
    text: {
      primary: "#ffffff",
      secondary: "#217DDE",
      blue: "#3CB6FF",
      black: "#363333",
      coalBlack: "#000000",
      headingGrey: "#636363",
      bodyGrey: "#606060",
      sky: "#5EB3D8 !important",
      skyblue: "#00D1FF !important",
      purple: "#9553CA !important",
      gold: "#ECB828 !important",
      agendaSelected: "rgb(255,255,255)!important",
      agendaUnSelected: "rgb(54,51,51)!important",
    },
    bg: {
      button:
        "linear-gradient(319.35deg, #217DDE 15.11%, #24A1E8 97.82%), #10302E",
      date: "linear-gradient(276.83deg, rgba(36, 161, 232, 0.05) 3.46%, rgba(33, 125, 222, 0.5) 35.03%, rgba(33, 125, 222, 0.53) 59.55%, rgba(33, 125, 222, 0.08) 84.64%)",
      countDown:
        "linear-gradient(291.32deg, rgba(34, 140, 226, 0) 20.27%, rgba(138, 167, 199, 0.37) 98.32",
      trackSelected:
        "linear-gradient(90.31deg, #112756 3.48%, rgba(17, 38, 86, 0) 121.14%), #0D2958",
      navWhite: "#ffffff",
      trackUnselected: "rgba(33, 125, 222, 0.1)",
      keynoteCardBottom: "rgba(33, 125, 222, 0.16)",
      speakerCardName: "#217DDE",
      footer: "#217DDE",
      eventDate: "rgba(0, 0, 0, 0.24)",
      agendaUnSelected: "rgba(204,233,255,0.75)!important",
      agendaSelected:
        "rgb(67, 18, 130)",
    },
    navbar: {
      boxShadow: "rgb(0 0 0 / 0.2) 0px 5px 1000px",
      transition: "all .2s ease",
    },
  },
  fontSize: {
    secondaryText: "2.5rem",
    primaryHeading: "6rem",
    normalText: "2rem",
    eventButton: "3rem",
    eventDate: "4rem !important",
    aboutHeadingText: "4.5rem",
    aboutSubheadingText: "2.2rem",
    sponsorTags: "3rem",
    footerTxt: "1.5rem",
    footerSubTxt: "1.8rem",
  },
  icon: {
    twitter: "#1da1f2 !important",
  },
};
